
/**
 * Get the footer from gpgroot.nl with the siteid and add it where the script tag is 
 * 
 */

import '@babel/polyfill/noConflict';
import 'whatwg-fetch';

class Footer {
  constructor() {
    this.me = document.querySelector('#gpgroot-footer-script');
    this.siteId = this.me.dataset.siteId;

    this.content = '';

    this.endPoint = 'https://www.gpgroot.nl/wp-json/gpgroot-footer/v1/get-footer?siteId=';
  }

  async init() {
    fetch(this.endPoint + this.siteId, {})
      .then(response => response.json())
      .then((data) => {
        if (data.status === 'OK') {
          this.content = data.html;
        }
      })
      .catch((error) => {
        console.error('Error:', error); // eslint-disable-line no-console
      })
      .finally(() => {
        if (!this.content.length) {
          return;
        }
        const footer = this.htmlToElement(this.content);
        this.insertAfter(this.me, footer);
      });
  }

  htmlToElement(html) { // eslint-disable-line class-methods-use-this
    const div = document.createElement('div');
    div.innerHTML = html.trim();

    // Change this to div.childNodes to support multiple top-level nodes
    return div.firstChild;
  }

  insertAfter(referenceNode, newNode) { // eslint-disable-line class-methods-use-this
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  }
}

document.addEventListener('DOMContentLoaded', async () => {
  const footer = new Footer();
  await footer.init();
});
